<template>
    <div class="login">
        <div class="centerWrapper">
            <div class="top">
                <div class="logo" @click="$router.push('/')">
                    <!-- <img src="../assets/imgs/brand.png"> -->
                    <img v-if="websiteLogo" :src="websiteLogo">
                    <div class="loginBox"></div>
                </div>
                <div class="right">
                    <span>登录</span>
                    <span @click="$router.replace('/register')">注册</span>
                </div>
            </div>
        </div>
        <div class="main">
            <img src="../assets/imgs/place40.png"/>
            <div class="loginBox">
                <!-- <div class="wel">Welcome to Home</div> -->
                <div class="way">账号登录</div>
                <div class="account">
                    <p>账号</p>
                    <div class="in">
                        <!-- <el-input v-model="telephone" placeholder="Username or email address"></el-input> -->
                        <el-input :class="{'wrong':accountWrong}" v-model="telephone" placeholder="输入手机号" @input="accountInput" autocomplete="off"></el-input>
                    </div>
                    <div class="tip">{{accountTip}}</div>
                </div>
                <div class="pwd">
                    <p>密码</p>
                    <div class="in">
                        <!-- <el-input v-model="password" placeholder="Password"></el-input> -->
                        <el-input type="password" show-password :class="{'wrong':pwdWrong}" v-model="password" placeholder="输入密码" @input="pwdInput" autocomplete="off"></el-input>
                    </div>
                    <div class="tip">{{pwdTip}}</div>
                </div>
                <div class="other">
                    <el-button type="text" @click="$router.push('/forgetPwd')">忘记密码</el-button>
                </div>
                <div class="btn">
                    <el-button type="primary" @click="login" :loading="loading">{{loading?"登录中":"登录"}}</el-button>
                </div>
            </div>
        </div>
        <div class="footer">
            <div>Copyright @2019-2024{{websiteName}}版权所有，并保留所有权利</div>
            <div @click="toFiling" style="cursor:pointer;width:fit-content;margin:auto">蜀ICP备2024081010号</div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import {loginByPwd} from "@/axios/api"
export default {
    data(){
        return{
            // userAcc:"18888888888",
            // password:"4QrcOUm6Wau+VuBX8g+IPg==",
            // telephone:"16666666666",
            // password:"test123456",
            telephone:"",
            password:"",
            accountTip:"",
            pwdTip:"",
            accountWrong:false,
            pwdWrong:false,
            loading:false,
            websiteLogo:"",
            websiteName:""
        }
    },
    methods: {
        // login(){
        //     axios({
        //         url:_root+"/v1/user/login",
        //         method:"post",
        //         data:{
        //             userName:"18888888888",
        //             password:"4QrcOUm6Wau+VuBX8g+IPg=="
        //         },
        //         headers:{
        //             "Content-Type":"application/x-www-form-urlencoded"
        //         }
        //     }).then(res=>{
        //         // console.log("登录",res)
        //     })
        // }

        clickLogin(){
            if(!this.userAcc){
                this.$message("请输入账号");
                return
            }
            if(!this.password){
                this.$message("请输入密码");
                return
            }
            login({
                userName:this.userAcc,
                password:this.password,
            }).then(res=>{
                // console.log("登录结果",res);
                if(res.success==true){
                    localStorage.HPUserInfo=JSON.stringify(res.data);
                    this.$router.push("/housingSource")
                }else{
                    this.$message(res.errorMessage)
                }
            })
        },
        // 登录
        login(){
            if(!this.telephone){
                this.accountTip="请输入账号";
                return
            }else if(!this.$commonFun.regTelephone(this.telephone)){
                this.accountTip="请正确输入手机号"
                return
            }else{
                this.accountTip=""
            }
            if(!this.password){
                this.pwdTip="请输入密码";
                return
            }else{
                this.pwdTip=""
            }

            this.loading=true;
            loginByPwd({
                telephone:this.telephone,
                password:this.password
            }).then(res=>{
                // // console.log("登录结果",res);
                if(res.code==200){
                    localStorage.AOGEYA_expireTime=new Date().getTime();
                    localStorage.AOGEYA_loginInfo=res.data;
                    localStorage.AOGEYA_lgPwd=this.password
                    // document.cookie="Authorization="+res.data;
                    // this.$message.success(res.msg||res.message);
                    setTimeout(()=>{
                        this.$router.push("/");
                        this.loading=false;
                    },1500)
                }else{
                    this.loading=false;
                    this.$commonFun.wrongTip(this,res.msg)
                }
            })
        },
        toFiling(){
            location.href="https://beian.miit.gov.cn"
        },
        // 账号输入时
        accountInput(){
            if(this.account==""){
                this.accountWrong=true
            }else{
                this.accountWrong=false
            }
        },
        // 密码输入时
        pwdInput(){
            if(this.password==""){
                this.pwdWrong=true
            }else{
                this.pwdWrong=false
            }
        }
    },
    created() {
        if(sessionStorage.websiteLogo){
            this.websiteLogo=sessionStorage.websiteLogo;
            this.websiteName=sessionStorage.websiteName;
        }
        this.$bus.$on('gettingConfig',()=>{
            // // console.log(2222,sessionStorage.websiteLogo);
            this.websiteLogo=sessionStorage.websiteLogo;
            this.websiteName=sessionStorage.websiteName;
        })
    },
}
</script>

<style lang="less" scoped>
.login{
    height: 100%;
    // background: url("../assets/imgs/loginBack.webp");
    background-size: 100% 100%;
    position: relative;
    .top{
        height: 122px;
        // padding: 25px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo{
            width: 200px;
            cursor: pointer;
            img{
                width: 100%;
                height: 85px;
                object-fit: contain;
            }
        }
        .right{
            display: flex;
            align-items: center;
            &>span{
                margin-left: 27px;
                font-size: 24px;
                font-size: 20px;
                font-weight: 400;
                color: #333;
                cursor: pointer;
            }
        }
    }
    .main{
        height: calc(100% - 242px);
        overflow: hidden;
        position: relative;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .loginBox{
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(800px,-50%);
            width: 539px;
            // height: 741px;
            height: 550px;
            border-radius: 40px;
            background: rgba(255, 255, 255, 0.7);
            box-sizing: border-box;
            padding: 39px;
            .wel{
                font-size: 20px;
                font-size: 16px;
                font-weight: 400;
                color: #333;
            }
            .way{
                margin-top: 10px;
                font-size: 50px;
                font-size: 34px;
                font-weight: 500;
                color: #333;
            }
            .account,.pwd{
                margin-top:140px;
                margin-top: 60px;
                p{
                    font-size: 20px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333;
                    margin: 0;
                }
                &>div{
                    margin-top: 7px;
                    .el-input{
                        height: 45px;
                        border-radius: 10px;
                        /deep/.el-input__inner{
                            background: rgba(255, 255, 255, 1);
                            border: 1px solid rgba(42, 130, 228, 1);
                            height: 45px;
                            line-height: 45px;
                        }
                        &.wrong{
                            /deep/.el-input__inner{
                                border-color: rgba(212, 48, 48, 1);
                            }
                        }
                    }
                }
                .tip{
                    height: 14px;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(212, 48, 48, 1);
                }
            }
            .pwd{
                margin-top: 56px;
                margin-top: 40px;
            }
            .other{
                display: flex;
                justify-content: flex-end;
            }
            .btn{
                margin-top: 34px;
                margin-top: 20px;
                text-align: center;
                .el-button{
                    width: 237px;
                    height: 54px;
                    border-radius: 10px;
                    background: rgba(119, 147, 65, 1);
                }
            }
        }
    }
    .footer{
        height: 40px;
        padding: 40px 0;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        color: rgba(128, 128, 128, 1);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        box-sizing: content-box;
    }
    // .loginBox{
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     transform: translate(-50%,-50%);
    //     width: 350px;
    //     height: 230px;
    //     background: #fff;
    //     border-radius: 20px;
    //     padding: 20px;
    //     box-sizing: border-box;
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: space-around;

    //     .project{
    //         margin-bottom: 20px;
    //         text-align: center;
    //         font-weight: bold
    //     }
    //     .fillItem{
    //         margin-bottom: 20px;
    //         display: flex;
    //         align-items: center;
    //         .el-input{
    //             flex: 1;
    //         }
    //     }
    // }
}
</style>